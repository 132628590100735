<template>

  <section id="ozgecmis">
    <div class="container">
      <div class="row text-center">
        <div class="col-lg-12">
        </div>
      </div>
      <div class="row m-t-md">
        <p style="font-size-adjust: inherit ; color: black" v-html="getAciklamasi.ozgecmis">

        </p>

      </div>

    </div>
  </section>

</template>

<script>
export default {
  name: "myinfo",

  computed:{
    getAciklamasi() {
      return this.$store.getters.getSiteIcerik;
    }
  },
}
</script>

<style scoped>

</style>
