<template>
  <section id="calismalar" class="bg-light">
    <div class="container">
      <div class="row">
        <div class="col-md-12  text-center">
          <h2><span class="text-success">Çalışmaları</span></h2>
          <p style="color: black" v-html="getAciklama.calismalar">
          </p>
        </div>
      </div>
      <div class="row m-t-lg">
        <div class="col-md-12">
          <a class="btn btn-success btn-sm btn-block" :href="getAciklama.downloadurl" target="_blank">Makale Listesini İndirin</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "myworkers",
  computed: {

    getAciklama(){
      return this.$store.getters.getSiteIcerik;
    }
  },
  data() {
    return {
      cont: "",
    }
  }
}
</script>

<style scoped>

</style>
