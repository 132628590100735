<template>
  <div>
    <!--    <img alt="Vue logo" src="./assets/logo.png">-->

    <mycoursel></mycoursel>
    <sitealti></sitealti>
    <myinfo></myinfo>
    <myworkers></myworkers>
    <mystudents></mystudents>
    <kvkk></kvkk>
    <myform></myform>
  </div>
</template>

<script>
import mycoursel from "@/components/header/mycoursel";
import myinfo from "@/components/info/myinfo";
import myworkers from "@/components/workers/myworkers";
import mystudents from "@/components/students/mystudents";
import Myform from "@/components/form/myform";
import kvkk from "@/components/kvkk/kvkk";
import sitealti from "@/components/salti/sitealti";


export default {
  name: 'App',
  components: {
    Myform,sitealti,
    myworkers, myinfo, mystudents, kvkk, mycoursel
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

#line25 {
  line-height: 25px !important;
}

#line35 {
  line-height: 35px !important;
}

#line45 {
  line-height: 45px !important;
}

#line55 {
  line-height: 55px !important;
}
</style>
