<template>
  <section id="ogrenciler">
    <div class="container">
      <div class="row text-center">
        <div class="col-md-12">
          <!-- <h2><span class="text-success">Öğrencileri </span></h2>-->
          <p v-html="getSiteAciklama.ogrenciyazi">

          </p>
        </div>
      </div>

      <div class="row m-t-lg text-center">
        <VueSlickCarousel v-bind="settings" v-if="getOgrenciler.length">

          <div class="col-sm-4" v-for="ogrenci in getOgrenciler" :key="ogrenci.id">
            <div class="team-member">
              <!--<img :src="ogrenci.ogrenciresim" class="img-circle img-small" alt="">-->
              <h4> {{ogrenci.isimsoyisim}}</h4>
              <p v-html="ogrenci.icerik"></p>
            </div>
          </div>

        </VueSlickCarousel>
      </div>


    </div>
  </section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: "mystudents",
  beforeCreate() {
    this.$store.dispatch("serverSliders");
  },
  computed:{
    getOgrenciler(){
      return this.$store.getters.getOgrenciler;
    },
    getSiteAciklama(){
      return this.$store.getters.getSiteIcerik;
    }
  },
  data() {
    return {
      cont: "",
      settings: {
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        rows: 3,
        slidesToScroll:3,
        slidesPerRow: 1,
        dots:true,
      }
    }
  },
  components: {
    VueSlickCarousel
  }
}
</script>

<style scoped>

</style>
