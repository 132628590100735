<template>
  <section id="kvkk" class="bg-light">
    <div class="container">
      <div class="row text-center">
        <div class="col-lg-12">
          <h2><span class="text-success">Kişisel Verileri Koruma Kanunu</span></h2>
        </div>
      </div>
      <div class="row m-t-md">
        <p style="font-size-adjust: inherit ; color: black" v-html="getAciklamasi.kvkk">

        </p>

      </div>

    </div>
  </section>

</template>

<script>
export default {
  name: "kvkk",
  computed: {
    getAciklamasi() {
      console.log(this.$store.getters.getSiteIcerik)
      return this.$store.getters.getSiteIcerik;
    }
  },
}
</script>

<style scoped>

</style>
