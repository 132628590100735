<template>
  <section id="onlineimza">
    <div class="container">
      <div class="row text-center">
        <div class="col-md-6 col-md-offset-3">
          <p v-html="getAcikalama.onlineimzaatin">

          </p>
        </div>
      </div>

      <div class="row text-center m-t-lg">
        <div class="col-md-6 col-md-offset-3">
          <form class="form-horizontal" @submit.prevent="imzala" role="form" method="post"
                action="">

            <div class="form-group">
              <label for="name" class="col-sm-2 control-label">İsim Soyisim:</label>

              <div class="col-sm-10">
                <input type="text" class="form-control" id="name" name="name"
                       v-model="imza.isim">
              </div>
            </div>


            <div class="form-group">
              <label for="kurum" class="col-sm-2 control-label">Kurum :</label>

              <div class="col-sm-10">
                <input type="text" class="form-control" id="kurum" name="kurum"
                       v-model="imza.kurum">
              </div>
            </div>
            <div class="form-group">
              <label for="unvan" class="col-sm-2 control-label">Ünvan</label>

              <div class="col-sm-10">
                <input type="text" class="form-control" id="unvan" v-model="imza.unvan" name="unvan">
              </div>
            </div>
            <div class="form-group">
              <label for="email" class="col-sm-2 control-label">Email</label>

              <div class="col-sm-10">
                <input type="email" class="form-control" @focusout="emailsorgulama" id="email" name="email"
                       placeholder="user@example.com" v-model="imza.email">
              </div>
            </div>
            <div class="form-group">
              <label for="telefon" class="col-sm-2 control-label">Telefon</label>

              <div class="col-sm-10">
                <input type="text" class="form-control" id="telefon" @focusout="telefonsorgulama" name="telefon"
                       placeholder="0(555) 555 55 55 " v-model="imza.telefon">
              </div>
            </div>

            <div class="form-group">
              <input type="checkbox" name="hsykpicerik" v-model="imza.hsypicerik"> Hakaret, saldırı , yalan , karalama ,
              provokasyon
              vb içerikteki yorum
              yazmadım
            </div>

            <div class="form-group">
              <input type="checkbox" name="okudumanladimkabulediyorum" v-model="imza.oakediyorum"> KVKK Okudum ve kabul
              ediyorum.
            </div>

            <div class="form-group">
              <div class="col-sm-12">

                <button id="submit" type="submit" name="submit" :disabled="aktif"
                        class="btn btn-success">İmzaya Katıl
                </button>
                &ensp;
                <button @click="imzaonay" type="button"
                        class="btn btn-primary">Onay Kodu Gir
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import * as Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "myform",
  data() {
    return {
      cont: "",
      aktif: true,
      code:"",
      imza: {
        isim: "",
        soyisim: "",
        email: "",
        kurum: "",
        unvan: "",
        telefon: "",
        hsypicerik: false,
        sgozuksunmu: false,
        oakediyorum: false,
      }

    }
  },
  computed: {
    getAcikalama() {
      return this.$store.getters.getSiteIcerik;
    }
  },
  watch:{
    aktif:function (val){
      this.aktif =  val;
    }
  },
  methods: {
    imzala() {
      if (this.imza.email !== "" && this.imza.isim !== "" &&  this.imza.kurum !== "" && this.imza.unvan !== "") {
        if (this.imza.hsypicerik && this.imza.oakediyorum) {
          var data = new FormData();
          data.append('email', this.imza.email)
          data.append('link', 'http://turkgeo.org/api/')
          data.append('isimsoyisim', this.imza.isim);

          data.append('kurum', this.imza.kurum);
          data.append('telefon', this.imza.telefon);
          data.append('unvan', this.imza.unvan);
          data.append('hskyicerik', this.imza.hsypicerik);
          data.append('kvvkokabulediyorum', this.imza.oakediyorum);
          axios.post("imzakaydet", data).then(res => {
            if (res.status === 200) {
              this.code = res.data.cashcode;
              this.imzaonay();
            }
          }).catch(e => {
            if (e.response.status === 403) {
              this.$swal.fire('Uyarı', 'Bu mail ile daha önceden kayıt yapılmış. Lütfen yöneticilerle irtibata geçiniz.!!', 'warning');
            } else {
              this.$swal.fire('Uyarı', 'Bir Hata OLuştu Lütfen Tekrar Deneyiniz!!', 'warning');
            }
          })
        } else {
          Swal.fire('Dikkat', 'Lütfen Hakaret, saldırı , yalan , karalama , provokasyon vb içerikteki yorum yazmadım ve Okudum ve kabul ediyorum. Kurallar kabul ediniz.', 'warning')
        }
      } else {
        Swal.fire('Dikkat', 'Lütfen Bilgileri Eksiksiz Giriniz', 'warning');
      }


    },
    emailsorgulama() {
      if (this.imza.email !== "") {
        var form = new FormData();
        form.append('email', this.imza.email)
        axios.post('/emailverification', form).then(response => {
          if (response.data.durum) {
            this.aktif = false;
          } else {
            this.aktif = true;
            this.$swal.fire("Dikkat", "Girilen mail adresi sistemde kayıtlıdır. Lütfen site yöneticileriyle iletişime geçiniz.", 'warning');
          }
        }).catch(e => {
          console.log(e)
        })
      }
    },
    telefonsorgulama() {
      if (this.imza.telefon) {
        var form = new FormData();
        form.append('telefon', this.imza.telefon)
        axios.post('/telefonverification', form).then(response => {
          console.log(response.data);
          if (response.data.durum) {
            this.aktif = false;
          } else {
            this.aktif = true;
            this.$swal.fire("Dikkat", "Girilen telefon numarasi sistemde kayıtlıdır. Lütfen site yöneticileriyle iletişime geçiniz.", 'warning');
          }

        }).catch(e => {
          console.log(e)
        })
      }
    },
    imzaonay() {
      this.$swal.fire({
        title: 'Dikkat',
        text: 'İmzanızın geçerli olabilmesi için lütfen mailinize gelen kodu giriniz.',
        input: 'text',
        icon: 'info',
        inputAttributes: {
          autocapitalize: 'off'
        },
        backdrop: false,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Onayla',
        denyButtonText: 'Tekrar Gönder',
        denyButtonColor: 'red',
        showLoaderOnConfirm: true,
        preConfirm: (login) => {
          if (login === "") {
            this.$swal.showValidationMessage('Lütfen Boş Bırakmayınız')
            return false;
          } else {
            var fd = new FormData();
            fd.append('onaykodu', login)
            fd.append('cashcode', this.code)
            return axios.post('emailonay', fd).then(res => {
              if (res.status === 200) {
                return true;
              } else {
                this.$swal.showValidationMessage('Bir Hata Oluştu Lütfen Tekrardan Deneyiniz.')
              }
            }).catch(e => {
              if (e.response.status === 403) {
                this.$swal.showValidationMessage('Onay Kodu Yanlış.')
              } else if (e.response.status === 401) {
                this.$swal.showValidationMessage('Daha önce işlem yapılmıştır. 3 saniye içinde pencere kapatılıyor.')
                var el = this;
                let i = 2;
                // eslint-disable-next-line no-unused-vars
                var sd = setInterval(function () {
                  el.$swal.showValidationMessage('Daha önce işlem yapılmıştır.' + i + ' saniye içinde pencere kapatılıyor.')
                  i -= 1;
                }, 1000)
                setTimeout(function () {
                  window.clearInterval(sd);
                  el.$swal.close();
                }, 3000)
              } else {
                this.$swal.showValidationMessage('Bir Hata Oluştu Lütfen Tekrardan Deneyiniz.')
              }
            })
          }
        },
        preDeny: () => {
          return true;
        },

        allowOutsideClick: false
      }).then(res => {
        if (res.isDenied) {
          this.emailtekrargonder();
        } else if (res.isConfirmed) {
          this.$swal.fire('İşlem Başarılı', "İşleminiz Başarılı Bir Şekilde Yapılmıştır. Katıldığınız İçin Teşekkür Ederiz.", 'success');
          this.imza.isim = "";
          this.imza.email = "";
          this.imza.soyisim = "";
          this.imza.kurum = "";
          this.imza.telefon = "";
          this.imza.tcno = "";
          this.imza.hsypicerik = false;
          this.imza.sgozuksunmu = false;
          this.imza.oakediyorum = false;
        }
      }).catch(e => {
        Swal.showValidationMessage(
            `Request failed: ${e}`
        )
      });
    },
    emailtekrargonder() {
      this.$swal.fire({
        title: 'Dikkat',
        text: 'Lütfen Email Adresinizi Giriniz.',
        input: 'text',
        icon: 'info',
        inputAttributes: {
          autocapitalize: 'off'
        },
        backdrop: false,
        showDenyButton: false,
        showCancelButton: true,
        cancelButtonText: 'İptal',
        confirmButtonText: 'Gönder',
        showLoaderOnConfirm: true,
        preConfirm: (login) => {
          if (login === "") {
            this.$swal.showValidationMessage('Lütfen Boş Bırakmayınız')
            return false;
          } else {
            var fd = new FormData();
            fd.append('email', login)

            return axios.post('emailtekraryolla', fd).then(res => {
              if (res.status === 200) {
                return true;
              } else {
                this.$swal.showValidationMessage('Bir Hata Oluştu Lütfen Tekrardan Deneyiniz.')
              }
            }).catch(e => {
              if (e.response.status === 404) {
                this.$swal.showValidationMessage('Email Bulunamadı. Lütfen Kayıt Olunuz.')
              } else {
                this.$swal.showValidationMessage('Bir Hata Oluştu Lütfen Tekrardan Deneyiniz.')
              }
            })

          }
        },
        allowOutsideClick: false
      }).then(res => {
        console.log(res);
        if (res.isConfirmed) {
          this.imzaonay();
        }
      }).catch(e => {
        Swal.showValidationMessage(
            `Request failed: ${e}`
        )
      });
    }
  }
}
</script>

<style scoped>

</style>
