import Vue from 'vue'
import App from './App.vue'
import VueSweetalert2 from 'vue-sweetalert2';
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)
import {store} from "@/store";

Vue.use(VueSweetalert2);
Vue.config.productionTip = false
axios.defaults.baseURL = "https://turkgeo.org/api/"
// VUEX  , VUEROUTER , vue-sweetalert2 , VueValidate, axios,

new Vue({
    render: h => h(App),
    store: store,
    created() {

        this.$store.dispatch("serverSiteIcerik");
        this.$store.dispatch("serverOgrenciler");
    },

}).$mount('#app')
