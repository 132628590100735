import Vuex from "vuex";
import axios from "axios";
import Vue from 'vue';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        sliders: [],
        ogrenciler: [],
        siteicerik: "",
        lang: "tr"
    },
    mutations: {
        setSliders(state, sliders) {
            state.sliders = sliders;
        },


        setOgrenciler(state, ogrenciler) {
            state.ogrenciler = ogrenciler;
        },
        setSiteIcerik(state, siteicerik) {
            state.siteicerik = siteicerik
        },
        setLang(state, lang) {
            state.lang = lang;
        }
    },
    getters: {
        getSliders: state => {
            return state.sliders
        },
        getOgrenciler: state => {
            return state.ogrenciler
        },
        getSiteIcerik: state => {
            return state.siteicerik
        }
    },
    actions: {
        serverSliders({commit}) {

              axios.get('slidergetir').then(res => {
                  console.log(res.data.data)
                  commit('setSliders', res.data.data);
              }).catch(e => {
                  console.log(e);
              })

        },
        serverOgrenciler({commit}) {
                axios.get('ogrenciler').then(res => {
                    commit('setOgrenciler',res.data.data);
                }).catch(e => {
                    console.log(e);
                })
        },
        serverSiteIcerik({commit}) {

                axios.get('icerik').then(res => {
                    console.log(res.data)
                    commit('setSiteIcerik',res.data.data);
                })

        }

    }
})
